<template>
    <div class="main">
        <div class="top-box">
            <img class="top-img" src="../../assets/image/success.png"/>
            <div class="top-text">恭喜您，投递成功！</div>
            <div class="info-text">
                <span>感谢您的投递</span>
                <span>人事部会在三个工作日内与您取得联系</span>
                <span>请保持手机畅通哦</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Success',
        components: {
        },
        data() {
            return {
                userId: '', // 用户id
                nowData: ''
            }},
        created() {
            const query = this.$route.query
            this.query = query
        },
        mounted:function(){

        },
        methods: {
        }
    }
</script>

<style scoped>
    .main{
        width: 100vw;
        min-height: 100vh;
        background-color:#ffffff;
        background-size: cover;
        box-sizing: border-box;
        position: relative;
    }
    .top-box{
        width: 100vw;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .top-img{
        width: 30vw;
        height: 30vw;
    }
    .top-text{
        margin-top: 30px;
        font-size: 25px;
    }
    .info-text{
        margin-top: 20px;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #686161;
    }
</style>
